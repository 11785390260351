<template>
  <el-card>
    <el-form :inline="true">
      <el-form-item prop="userId" label="用户名:">
        <el-select
          v-model="inForm.userId"
          filterable
          @focus="onFocus"
          remote
          placeholder="请输入关键词"
          :remote-method="userRemoteFun"
          @change="userIdSelectChange"
        >
          <el-option
            v-for="item in userOption"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="userId" label="客户名:">
        <el-input type="text" disabled v-model="inForm.owinUserName">
        </el-input>
      </el-form-item>
      <el-form-item label="出库类型">
        <el-select
          style="width: 300px"
          v-model="inForm.outStorageType"
          placeholder="筛选出库类型内容"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联订单编号">
        <el-input
          style="width: 300px"
          v-model="inForm.linkOrderNumber"
          placeholder="关联订单编号"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          style="width: 300px"
          v-model="inForm.remark"
          placeholder="出库单备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-table
      ref="productRef"
      :summary-method="getSummaries"
      :show-summary="true"
      :data="inForm.productList"
      border
      height="600"
    >
      <el-table-column type="index" label="#" width="80"></el-table-column>
      <el-table-column prop="productName" label="商品名称"></el-table-column>
      <el-table-column prop="count" label="商品数量"></el-table-column>
      <el-table-column prop="price" label="商品价格"></el-table-column>
      <el-table-column label="商品总价格">
        <template slot-scope="scope">
          {{ scope.row.count * scope.row.price }}
        </template>
      </el-table-column>
      <el-table-column prop="modelName" label="型号"></el-table-column>
      <el-table-column prop="productSpecs" label="规格"></el-table-column>
      <el-table-column
        prop="remark"
        show-overflow-tooltip
        label="商品备注"
      ></el-table-column>
      <el-table-column label="编辑">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="openEditFormDialog(scope.row)"
            type="primary"
            icon="el-icon-edit"
            >编辑</el-button
          >
          <el-button
            size="mini"
            @click="deleteProduct(scope.row)"
            type="danger"
            icon="el-icon-delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row style="margin-top: 20px">
      <div class="btn_group" style="float: right">
        <el-button type="success" @click="addProductDialogVisabled = true"
          >新增商品</el-button
        >
        <el-button type="primary" @click="inStorageSubmit">保存订单</el-button>
        <el-button type="info" @click="cancelInStorage">取消订单</el-button>
      </div>
    </el-row>

    <!-- 新增商品对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="新增商品"
      :visible.sync="addProductDialogVisabled"
      @close="addFormDialogClose"
    >
      <el-form
        ref="addProductFormRef"
        :model="addProductForm"
        :rules="addProductFormRules"
        label-width="100"
      >
        <el-form-item prop="productName" label="商品名称">
          <el-select
            v-model="addProductForm.productId"
            filterable
            @focus="onFocus"
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="querySearch"
            @change="productSelect"
          >
            <el-option
              v-for="item in productOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品型号">
          <el-select
            v-model="addProductForm.modelName"
            placeholder="请输入关键词"
          >
            <el-option
              v-for="item in modelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品规格">
          <el-select
            v-model="addProductForm.productSpecs"
            placeholder="请输入关键词"
            @change="specsOptionsChange"
          >
            <el-option
              v-for="item in specsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="count" label="商品数量">
          <el-input
            v-model="addProductForm.count"
            autocomplete="off"
            placeholder="商品数量"
          ></el-input>
        </el-form-item>
        <el-form-item prop="price" label="商品价格">
          <el-input
            v-model="addProductForm.price"
            autocomplete="off"
            placeholder="商品价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品备注">
          <el-input
            v-model="addProductForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addProductDialogVisabled = false">取 消</el-button>
        <el-button type="primary" @click="addFormDialogConfim">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑商品对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="编辑商品"
      :visible.sync="editProductDialogVisabled"
      @close="editFormDialogClose"
    >
      <el-form
        ref="editProductFormRef"
        :model="editProductForm"
        :rules="editProductFormRules"
        label-width="100"
      >
        <el-form-item prop="productName" label="商品名称">
          <el-input
            v-model="editProductForm.productName"
            disabled
            autocomplete="off"
            placeholder="商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="productName" label="商品型号">
          <el-input
            v-model="editProductForm.modelName"
            disabled
            autocomplete="off"
            placeholder="商品型号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="productName" label="商品规格">
          <el-input
            v-model="editProductForm.productSpecs"
            disabled
            autocomplete="off"
            placeholder="商品规格"
          ></el-input>
        </el-form-item>
        <el-form-item prop="count" label="商品数量">
          <el-input
            v-model="editProductForm.count"
            autocomplete="off"
            placeholder="商品数量"
          ></el-input>
        </el-form-item>
        <el-form-item prop="price" label="商品价格">
          <el-input
            v-model="editProductForm.price"
            autocomplete="off"
            placeholder="商品价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品备注">
          <el-input
            v-model="editProductForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editProductDialogVisabled = false">取 消</el-button>
        <el-button type="primary" @click="editFormDialogConfim"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import { getOutStorageTypeOptions, addNew } from '@/api/outStorage.js'
import { getFilterProduct } from '@/api/product.js'
import { getUserOptions } from '@/api/user.js'
import { validateNumber } from '@/utils/validate.js'
import { iosOnFocus } from '@/utils/iosPlatform.js'
export default {
  data() {
    let validateNumberValid = (rule, value, callback) => {
      if (validateNumber(value)) {
        var number = Number(value)

        if (number < 0) {
          callback(new Error('当前数据不能为负数'))
        } else {
          callback()
        }
      } else {
        callback(new Error('当前类型为非数字类型'))
      }
    }
    return {
      typeOptions: [],
      inForm: {
        userId: null,
        owinUserName: '',
        outStorageType: 1,
        remark: '',
        linkOrderNumber: '',
        productList: [],
      },
      userOption: [],
      // 商品远程搜索
      productOptions: [],
      modelOptions: [],
      specsOptions: [],
      // 新增表单对话框显示隐藏
      addProductDialogVisabled: false,
      // 新增商品表单数据
      addProductForm: {
        productId: null,
        productName: '',
        modelName: null,
        productSpecs: null,
        count: '1',
        price: '',
        remark: '',
      },
      addProductFormRules: {
        productName: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          },
        ],
        productName: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          },
        ],
        count: [
          {
            required: true,
            message: '请输入商品数量',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入商品价格',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
      },
      // 编辑商品对话框显示隐藏
      editProductDialogVisabled: false,
      editProductForm: {
        productId: null,
        productName: '',
        modelName: null,
        productSpecs: null,
        count: '1',
        price: '',
        remark: '',
      },
      editProductFormRules: {
        productName: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          },
        ],
        count: [
          {
            required: true,
            message: '请输入商品数量',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入商品价格',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    // 用户远程搜索
    async userRemoteFun(key) {
      if (key !== '') {
        let { data } = await getUserOptions(key)
        this.userOption = data
      }
    },

    // 用户名选中更改
    async userIdSelectChange(dataId) {
      let entity = this.userOption.filter((item) => {
        return item.id == dataId
      })[0]

      this.inForm.owinUserName = entity.customerName
    },

    // 获取设备类型状态
    async getOptions() {
      let { data } = await getOutStorageTypeOptions()
      this.typeOptions = data
    },
    // 表格尾行合计行
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        // 第一列索引
        if (index === 0) {
          sums[index] = '总价'
          return
        }
        //第二列商品名称
        if (index === 1) {
          sums[index] = ''
        }

        // 第二列商品名称
        if (index === 2) {
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' 件'
          } else {
            sums[index] = 'N/A'
          }
          return
        }
        // 第三列单价
        if (index === 3) {
          sums[index] = ''
          return
        }
        // 第四列总价
        if (index === 4) {
          const priceArr = data.map((item) => {
            return Number(item['price'] * item['count'])
          })

          if (!priceArr.every((value) => isNaN(value))) {
            sums[index] = priceArr
              .reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              .toFixed(2)
            sums[index] += ' 元'
            return
          } else {
            sums[index] = 'N/A'
            return
          }
        }

        sums[index] = ''
      })

      this.$nextTick((_) => {
        this.$refs['productRef'].doLayout()
      })

      return sums
    },
    async querySearch(queryString) {
      if (queryString !== '') {
        let { data } = await getFilterProduct(queryString)
        this.productOptions = data
      } else {
        this.productOptions = []
      }
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    addFormDialogClose() {
      this.productOptions = []
      this.modelOptions = []
      this.specsOptions = []
      this.addProductForm.productId = null
      this.addProductForm.modelName = null
      this.addProductForm.productSpecs = null
      this.resetForm('addProductFormRef')
    },
    productSelect(id) {
      let item = this.productOptions.filter((item) => {
        return item.id == id
      })[0]
      //      console.log(item)
      this.addProductForm.productName = item.productName
      this.addProductForm.price = item.price

      if (item.modelOptions && item.modelOptions.length > 0) {
        this.modelOptions = item.modelOptions
        this.addProductForm.modelName = item.modelOptions[0].value
      }

      // if (item.specsOptions && item.specsOptions.length > 0) {
      //   this.specsOptions = item.specsOptions
      //   this.addProductForm.productSpecs = item.specsOptions[0].value
      // }

      if (item.attribute && item.attribute.length > 0) {
        this.specsOptions = item.attribute
        this.addProductForm.productSpecs = item.attribute[0].value
        this.addProductForm.price = item.attribute[0].price
      }
    },
    specsOptionsChange(id) {
      if (this.specsOptions.some((u) => u.value == id)) {
        let item = this.specsOptions.filter((i) => {
          return i.value == id
        })[0]

        this.addProductForm.productSpecs = item.value
        this.addProductForm.price = item.price
      }
    },
    addFormDialogConfim() {
      this.$refs.addProductFormRef.validate((valid) => {
        if (valid) {
          let filterEntity = this.inForm.productList.filter((item) => {
            return (
              item.productId == this.addProductForm.productId &&
              item.modelName == this.addProductForm.modelName &&
              item.productSpecs == this.addProductForm.productSpecs
            )
          })

          if (filterEntity.length > 0) {
            return this.$message.error('当前订单中已经存在该商品')
          } else {
            this.inForm.productList.push({
              productId: this.addProductForm.productId,
              productName: this.addProductForm.productName,
              modelName: this.addProductForm.modelName,
              productSpecs: this.addProductForm.productSpecs,
              count: parseInt(this.addProductForm.count),
              price: Number(this.addProductForm.price).toFixed(2),
              remark: this.addProductForm.remark,
            })
            this.addProductDialogVisabled = false
            this.$message.success('添加商品成功')
          }
        } else {
          return this.$message.error('当前商品参数填写不正确.')
        }
      })
    },
    openEditFormDialog(data) {
      this.editProductForm = { ...data }
      this.editProductDialogVisabled = true
    },
    editFormDialogClose() {
      this.resetForm('editProductFormRef')
    },
    // 确认编辑商品
    editFormDialogConfim() {
      this.$refs.editProductFormRef.validate((valid) => {
        if (valid) {
          let filterEntity = this.inForm.productList.filter((item) => {
            return (
              item.productId == this.editProductForm.productId &&
              item.modelName == this.editProductForm.modelName &&
              item.productSpecs == this.editProductForm.productSpecs
            )
          })

          if (filterEntity.length > 0) {
            this.inForm.productList.forEach((item, index, arr) => {
              if (
                item.productId == this.editProductForm.productId &&
                item.modelName == this.editProductForm.modelName &&
                item.productSpecs == this.editProductForm.productSpecs
              ) {
                arr[index].productId = this.editProductForm.productId
                arr[index].productName = this.editProductForm.productName
                arr[index].count = parseInt(this.editProductForm.count)
                arr[index].price = Number(this.editProductForm.price).toFixed(2)
                arr[index].remark = this.editProductForm.remark
              }
            })
          } else {
            this.$message.error('编辑失败,当前商品不存在.')
          }

          this.resetForm('editProductFormRef')

          this.editProductDialogVisabled = false
          this.$message.success('编辑商品成功')
        } else {
          return this.$message.error('当前商品参数填写不正确.')
        }
      })
    },
    // 从订单中删除商品
    deleteProduct(row) {
      this.$confirm('当前操作将会将商品从订单中删除是否确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.inForm.productList = this.inForm.productList.filter((item) => {
            return !(
              item.productId == row.productId &&
              item.modelName == row.modelName &&
              item.productSpecs == row.productSpecs
            )
          })

          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    async inStorageSubmit() {
      if (!this.inForm.userId) {
        return this.$message.error('客户不能为空')
      }

      if (this.inForm.productList.length <= 0) {
        return this.$message.error('商品数量不能为空')
      }

      let productList = this.inForm.productList.map((item) => {
        return {
          productId: item.productId,
          productName: item.productName,
          modelName: item.modelName,
          productSpecs: item.productSpecs,
          amount: item.count,
          price: item.price,
          remark: item.remark,
        }
      })

      let data = {
        userId: this.inForm.userId,
        owinUserName: this.inForm.owinUserName,
        outStorageType: this.inForm.outStorageType,
        linkOrderNumber: this.inForm.linkOrderNumber,
        remark: this.inForm.remark,
        product: productList,
      }

      await addNew(data)

      this.$message.success('新增出库单成功')

      this.$router.push({ path: '/OutStorage' })
    },
    cancelInStorage() {
      this.$router.push({ path: '/OutStorage' })
    },
    onFocus(event) {
      iosOnFocus.call(this,event);
    },
  },
}
</script>
<style lang="scss" scoped>
.btn_group:after {
  content: '020';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.el-form-item {
  margin-bottom: 25px;
}
.el-select {
  width: 100%;
}
</style>
